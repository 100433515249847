import { parseGid } from '/utils/graph-ql';

const getCategoryId = (productType) => {
	const productTypeLower = productType?.toLowerCase();
	if (!productTypeLower) {
		return 2562;
	}
	if (productTypeLower === 'long johns') {
		return 1807;
	}
	if (productTypeLower.includes('shirt')) {
		return 2745;
	}
	if (productTypeLower.includes('sock')) {
		return 2745;
	}
	if (productTypeLower.includes('bra')) {
		return 214;
	}
	if (productTypeLower.includes('shorts')) {
		return 207;
	}
	if (productTypeLower.includes('shirt')) {
		return 212;
	}
	if (productTypeLower.includes('blazer')) {
		return 1594;
	}
	if (productTypeLower.includes('blazer')) {
		return 1594;
	}
	if (productTypeLower.includes('swim')) {
		return 211;
	}
	return 2562;
};

export const formatProductsAxon = (products) => {
	return products.map((product) => {
		// per axon on slack if no product id default to a hidden one
		const idToUse = product?.shopifyProductId
			? parseGid(product.shopifyProductId)
			: '6785612873797';
		const axonProduct = {
			item_id: idToUse,
			item_name: product.title,
			price: parseFloat(product.price),
			quantity: product?.quantity || 1,
			item_category: product.productType,
			item_category_id: getCategoryId(product.productType),
			image_url: product.image,
			item_brand: 'Shinesty',
		};

		if (product.shopifyVariantId) {
			axonProduct.item_variant_id = parseGid(product.shopifyVariantId);
		} else {
			axonProduct.item_variant_id = parseGid(product?.variants[0]?.id);
		}

		return axonProduct;
	});
};
