import { Accordion } from '/components/Accordion';
import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import NavItem from '/components/top-nav/mobile-menu/NavItem';
import NavLink from '/components/top-nav/mobile-menu/NavLink';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const NavGroup = ({ navGroup, resetMenu, resetNavigationState, updateNavigationState }) => {
	if (navGroup.location === 'start' && navGroup.children) {
		return (
			<>
				{navGroup.children.map((navItem, navItemIndex) => {
					if (!navItem.children?.length) {
						return (
							<a
								href={navItem.href}
								className="border-b-[1px] h-14 w-full flex justify-between items-center px-6"
								key={navItemIndex}>
								<Typography className="capitalize font-semibold !tracking-wider" variant="body">
									{navItem.title}
								</Typography>
								<Icon name="caretRight" height="10px" width="10px" />
							</a>
						);
					}

					return (
						<Clickable
							className="border-b-[1px] h-14 w-full flex justify-between items-center px-6"
							key={navItemIndex}
							onClick={() => {
								updateNavigationState(navItem);
							}}>
							<Typography className="capitalize font-semibold !tracking-wider" variant="body">
								{navItem.title}
							</Typography>
							<Icon name="caretRight" height="10px" width="10px" />
						</Clickable>
					);
				})}
			</>
		);
	}

	const showNavLink = !navGroup?.title?.includes('Build') && !navGroup?.title?.includes('Sub');

	return (
		<>
			{navGroup.title && (
				<>
					<Clickable
						className="border-b-[1px] flex h-14 items-center justify-start px-6 w-full"
						onClick={() => {
							resetNavigationState();
						}}>
						<Icon className="mr-3" name="caretLeft" height="10px" width="10px" />
						<Typography
							variant="body"
							className="capitalize font-semibold mt-[5px] !tracking-wider">
							{navGroup.title}
						</Typography>
					</Clickable>
					{showNavLink && <NavLink item={navGroup} showShopAll={true} resetMenu={resetMenu} />}
				</>
			)}

			<Accordion>
				{navGroup?.children?.map((navItem, i) => {
					return (
						<NavItem
							key={i}
							navItem={navItem}
							position={i}
							resetMenu={resetMenu}
							updateNavigationState={updateNavigationState}
						/>
					);
				})}
			</Accordion>
		</>
	);
};

NavGroup.propTypes = {
	navGroup: PropTypes.object,
	resetMenu: PropTypes.func,
	resetNavigationState: PropTypes.func,
	updateNavigationState: PropTypes.func,
};

export default NavGroup;
