import { checkProductTags } from '/utils/product-tags';
import { checkProductVariantsSku } from '/utils/common-utils';
const getProductSticker = (product) => {
	let sticker;
	const productType =
		product?.shopifyProductType?.toLowerCase() || product?.productType?.toLowerCase();

	if (
		product?.variants_inventory_count <= 0 &&
		checkProductTags(product?.tags, 'feature: always on') &&
		!checkProductTags(product?.tags, 'no_out_of_stock: true')
	) {
		sticker = 'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/returningsoon.svg';
	} else if (
		product?.variants_inventory_count <= 0 &&
		!checkProductTags(product?.tags, 'no_out_of_stock: true')
	) {
		sticker = 'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/soldout.svg';
	} else if (checkProductTags(product?.tags, 'feature: subscription exclusive')) {
		sticker =
			'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/subscription-exclusive.svg';
	} else if (['boys underwear', 'boys underwear - pack'].includes(productType)) {
		sticker = 'https://assets.shinesty.com/icons/boys-sticker.svg';
	} else if (checkProductVariantsSku(product, '10403')) {
		sticker = 'https://assets.shinesty.com/icons/imrpoved+fit.svg';
	} else if (checkProductTags(product?.tags, 'deal_of_the_week: true')) {
		sticker =
			'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/deal-of-the-week-larger.svg';
	} else if (checkProductTags(product?.tags, 'flash_sale: true')) {
		sticker = 'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/Flasher_Icon-02.svg';
	} else if (checkProductTags(product?.tags, 'feature: glows')) {
		sticker = 'https://cdn.shinesty.com/2024-08-27/glow in the dark.svg';
	} else if (checkProductTags(product?.tags, 'feature: fly')) {
		sticker = 'https://cdn.shinesty.com/2024-08-27/with fly boxer.svg';
	} else if (checkProductTags(product?.tags, 'feature: limited')) {
		sticker = 'https://cdn.shinesty.com/2024-08-27/new style.svg';
	} else if (checkProductTags(product?.tags, 'feature: long leg')) {
		sticker = 'https://cdn.shinesty.com/2024-08-27/loong.svg';
	} else if (checkProductTags(product?.tags, 'feature: fur')) {
		sticker = 'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/fur.svg';
	} else if (productType === 'boxers - trunk') {
		sticker = 'https://cdn.shinesty.com/2024-08-27/mens trunks.svg';
	} else if (productType === 'boxers - brief') {
		sticker = 'https://assets.shinesty.com/icons/briefs-sticker-men.svg';
	} else if (checkProductTags(product?.tags, 'feature: shop now')) {
		sticker = 'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/ships-now.svg';
	} else if (checkProductTags(product?.tags, 'category: pre order')) {
		sticker = 'https://d3w0qy4ktfyhb7.cloudfront.net/icons/product-stickers/preorder.svg';
	} else if (
		['boxers - cooling - fly', 'bralettes - cooling', 'thongs - cooling'].includes(productType) ||
		checkProductTags(product?.tags, 'feature: cooling')
	) {
		sticker = 'https://cdn.shinesty.com/2024-08-27/cooling.svg';
	} else if (['thongs - modal', "women's boxer"].includes(productType)) {
		sticker = 'https://cdn.shinesty.com/2024-08-27/new style.svg';
	}

	return sticker;
};

module.exports = {
	getProductSticker,
};
