import Countdown from '/components/Countdown';
import Price from '../Price';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { useGlobalStore } from '/state/global-context';

const DiscountBasedCartDetail = ({ processedCart, subscriptionInCart }) => {
	const globalStore = useGlobalStore();

	const promotion = globalStore.getPromotionalConfig();

	if (!promotion || !promotion.miniCartTickers || promotion.promotionType !== 'cart-item') {
		return null;
	}
	const discountAmount = Number(promotion.discountCodeValue) / 100;

	const progress =
		processedCart.subTotal * (1 - discountAmount) <= 124
			? processedCart.subTotal * (1 - discountAmount)
			: 125;

	return (
		<div className="bg-gray-600 text-center py-2 text-white">
			<div className="pb-1">
				{/* TODO PROMOTION: This should pull from the GCC? */}
				<Typography variant="body" className="capitalize font-semibold text-xs">
					Use Code <span className="text-[#FABC3C] font-semibold">{promotion.discountCode}</span> at
					checkout and save{' '}
					<Price price={processedCart.subTotal * discountAmount} showSymbol={true} />
				</Typography>
			</div>
			{processedCart.subTotal < promotion.minimumCartSubtotal && !subscriptionInCart ? (
				<>
					<div className="flex px-2">
						<Typography variant="micro">
							You&apos;re on the way to <span className="font-semibold">Free Shipping:</span>
						</Typography>
						<Typography variant="body" className="ml-auto">
							${promotion.minimumCartSubtotal}
						</Typography>
					</div>
					<div className="h-4 bg-gray-300 mx-2 rounded-full text-right">
						<div
							className="h-full bg-[#FABC3C]  rounded-l-full"
							style={{ width: `${progress}%` }}></div>
					</div>
				</>
			) : (
				<>
					{subscriptionInCart ? (
						<div>
							<Typography variant="subhead-md">free shipping with your subscription</Typography>
						</div>
					) : (
						<div>
							<Typography variant="subhead-md">You&apos;ve unlocked FREE shipping</Typography>
						</div>
					)}
				</>
			)}
			Sale ends in <Countdown countdownDate={promotion.dateEnd} />
		</div>
	);
};

DiscountBasedCartDetail.propTypes = {
	processedCart: PropTypes.object,
	subscriptionInCart: PropTypes.bool,
};

export default DiscountBasedCartDetail;
