import apolloClient from '/utils/apollo-client';
import { createGid } from '/utils/graph-ql';
import { formatProduct as formatProductShopify } from '/utils/product-normalizers/shopify';
import { getNewPackProductTypes } from '/services/packs';
import { removeFalsy } from '/utils/common-utils';

import {
	GET_PRODUCTS_FOR_COLLECTION,
	GET_PRODUCTS_METADATA,
	GET_PRODUCT_BY_HANDLE,
	GET_PRODUCT_BY_ID,
	GET_PRODUCT_PRICE,
	GET_RECOMMENDED_PRODUCTS,
	GET_SELLING_PLANS,
	GET_VARIANT_INVENTORY,
} from '/services/queries/shopify';

const getCompleteProduct = async (productHandle) => {
	const product = await getProduct(productHandle);
	const extraProducts = await getExtraProducts(product);

	return {
		product,
		...extraProducts,
	};
};

const getCompleteProductById = async (productId) => {
	if (!productId) {
		return;
	}

	const product = await getProductById(productId);
	const extraProducts = await getExtraProducts(product);

	return {
		product,
		...extraProducts,
	};
};

const getExtraProducts = async (product) => {
	if (!product) {
		return {};
	}

	product = JSON.parse(JSON.stringify(product));

	const newPackProductTypes = getNewPackProductTypes();

	let joinedProducts = [];
	if (product?.tags?.includes('joinedproduct')) {
		const joinedProductHandles = product.tags
			.filter((t) => {
				return t.includes('joinedproduct:');
			})
			.map((x) => {
				return x.split(':')[1].trim();
			});

		joinedProducts = await Promise.all(joinedProductHandles.map((id) => getProductById(id)));
		joinedProducts = removeFalsy(joinedProducts);

		joinedProducts = joinedProducts.sort((jp) => {
			return jp.handle.indexOf('pants') > -1 ? -1 : 1;
		});

		product.hasJoinedProducts = true;
	}

	let packProducts = [];
	if (
		!newPackProductTypes.includes(product?.productType) &&
		product?.tags?.some((t) => t.includes('packproduct:'))
	) {
		const packProductHandles = product.tags
			.filter((t) => {
				return t.includes('packproduct:');
			})
			.map((x) => {
				return x.split(':')[1].trim();
			});

		packProducts = await Promise.all(packProductHandles.map((id) => getProductById(id)));
		packProducts = removeFalsy(packProducts);

		product.hasPackProducts = true;
	} else {
		product.hasPackProducts = false;
	}

	let hasSwappedVariants = false;

	if (product?.tags?.find((t) => t.includes('mensproduct:'))) {
		let mensProductId = product.tags.find((t) => t.includes('mensproduct:'));

		if (mensProductId) {
			mensProductId = mensProductId.split(':')[1].trim();
			const swapProduct = await getProductById(mensProductId);
			product.variants = swapProduct.variants;

			const foundSwapProductSizeOption = swapProduct.options?.find((option) => {
				return option.name === 'Size';
			});

			if (foundSwapProductSizeOption?.values) {
				if (!product.options) {
					product.options = [];
				}

				const foundProductSizeOptionIndex = product.options.findIndex((option) => {
					return option.name === 'Size';
				});

				// console.log(foundProductSizeOption);
				//
				if (foundProductSizeOptionIndex > -1) {
					product.options[foundProductSizeOptionIndex].values = foundSwapProductSizeOption.values;
				} else {
					product.options.push(foundSwapProductSizeOption);
				}
			}
			hasSwappedVariants = true;
		}
	}

	let freeProduct = null;
	if (product?.tags?.find((t) => t.includes('freeproduct :'))) {
		let freeProductId = product.tags.find((t) => t.includes('freeproduct :'));

		if (freeProductId) {
			freeProductId = freeProductId.split(':')[1].trim();
			freeProduct = await getProductById(freeProductId);
		}
	}

	return {
		product,
		freeProduct,
		hasSwappedVariants,
		joinedProducts,
		packProducts,
	};
};

const getProduct = async (productHandle) => {
	if (!productHandle) {
		return;
	}

	const { data } = await apolloClient.query({
		query: GET_PRODUCT_BY_HANDLE,
		variables: { productHandle },
	});

	if (!data.productByHandle) {
		return;
	}

	return formatProductShopify(data.productByHandle);
};

const getProductById = async (productId) => {
	if (!productId) {
		return;
	}

	const { data } = await apolloClient.query({
		query: GET_PRODUCT_BY_ID,
		variables: { productId: createGid('Product', productId) },
	});

	if (!data.product) {
		return;
	}

	return formatProductShopify(data.product);
};

const getPriceById = async (productId) => {
	if (!productId) {
		return;
	}

	const { data } = await apolloClient.query({
		query: GET_PRODUCT_PRICE,
		variables: { productId: createGid('Product', productId) },
	});

	if (!data.product) {
		return;
	}

	return formatProductShopify(data.product);
};

const getProductsShopify = async (collectionHandle, limit = 100) => {
	const { data } = await apolloClient.query({
		query: GET_PRODUCTS_FOR_COLLECTION,
		variables: { collectionHandle, limit },
	});

	const products =
		data.collectionByHandle?.products?.edges.map((p) => {
			return formatProductShopify(p.node);
		}) || [];

	// This is to match algolia's payload so we can easily toggle between the two services.
	return { facets: [], hits: products, nbPages: 1 };
};

const getProductsMetadata = async (productHandles) => {
	const rawData = await Promise.all(
		productHandles.map((handle) => {
			return apolloClient.query({
				query: GET_PRODUCTS_METADATA,
				variables: {
					productHandle: handle,
				},
			});
		}),
	);

	return rawData.reduce((memo, raw) => {
		const formatted = formatProductShopify(raw.data.productByHandle);
		if (formatted) {
			memo.push(formatted);
		}
		return memo;
	}, []);
};

const getRecommendedProducts = async (productId) => {
	const rawData = await apolloClient.query({
		query: GET_RECOMMENDED_PRODUCTS,
		variables: {
			productId: productId,
		},
	});
	return rawData.data.productRecommendations.reduce((memo, raw) => {
		const formatted = formatProductShopify(raw);
		if (formatted) {
			memo.push(formatted);
		}
		return memo;
	}, []);
};

const getVariantInventory = async (variantId) => {
	return apolloClient.query({
		query: GET_VARIANT_INVENTORY,
		variables: {
			variantId: variantId,
		},
	});
};

const getButtonImageSrc = (product) => {
	if (!product) {
		return;
	}

	// support button images from algolia or shopify
	if (product?.alternateProductImages?.buttonSrc) {
		return product?.alternateProductImages?.buttonSrc;
	}

	if (product?.auxiliaryImages?.buttonSrc) {
		return product?.auxiliaryImages?.buttonSrc;
	}

	if (product?.buttonImg?.value) {
		return product.buttonImg.value;
	}

	if (product?.image) {
		return product.image;
	}

	if (product?.images?.[0]?.imageSrc) {
		return product?.images?.[0]?.imageSrc;
	}

	// default to the product image if pack/button images are not available.
	return product.images?.[0].src;
};

const getProductsSellingPlans = async (productIds) => {
	const promises = productIds.map((productId) => {
		return apolloClient.query({
			query: GET_SELLING_PLANS,
			variables: {
				productId,
			},
		});
	});

	const products = await Promise.all(promises);

	const formatted = products.map((product) => {
		return formatProductShopify(product);
	});

	return formatted.length === 1 ? formatted[0] : formatted;
};

module.exports = {
	getButtonImageSrc,
	getCompleteProduct,
	getCompleteProductById,
	getPriceById,
	getProduct,
	getProductById,
	getProductsMetadata,
	getProductsSellingPlans,
	getProductsShopify,
	getRecommendedProducts,
	getVariantInventory,
};
