import Cookies from 'js-cookie';
import Link from 'next/link';
import Typography from '/components/Typography';

const FookieCartInfo = () => {
	if (Cookies.get('shLandingExperience') !== 'fortune24') {
		return null;
	}
	return (
		<div className="border-2 border-v2-brown-darker grid px-2 rounded-lg m-2 gap-2">
			<Typography variant="body" className="font-bold">
				Fortune Freeby Promotion:
			</Typography>
			<Typography variant="body">Spend $50, get a free mystery pair of socks</Typography>
			<Typography variant="body">Spend $75, get a free mystery pair of underwear</Typography>
			<Typography variant="micro">Discount will apply automatically after qualifying</Typography>
			<Link href="/collections/mystery-product">
				<a>
					<Typography className="text-v2-hunter-green hover:text-v2-brown transition-colors duration-300 ease-in-out cursor-pointer">
						Shop Mystery Product
					</Typography>
				</a>
			</Link>
		</div>
	);
};
export default FookieCartInfo;
