import Clickable from '/components/Clickable';
import Image from '/components/Image';
import Loading from '/components/Loading';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { createGid } from '../../utils/graph-ql';
import { getProductById } from '/services/products';
import { linesFromVariants } from '/utils/product';
import { useCartStore } from '/state/cart-context';
import { useEffect, useState } from 'react';

const VariantButton = ({ addToCart, product, variant, isMobile }) => {
	const [addToCartLoading, setAddToCartLoading] = useState(false);

	const disabled = !variant?.dcInventory?.value || variant?.dcInventory?.value < 1;
	const title = variant.title == 'OSFA' ? 'Add To Cart' : variant.title.replace('US Jacket', '');

	return (
		<>
			{addToCartLoading ? (
				<Loading className="ml-2" height="30px" width="30px" />
			) : (
				<Clickable
					className={classNames(
						'font-bold min-w-[54px] p-2 rounded border border-v2-brown bg-primary',
						isMobile ? 'bg-white border-gray-200 text-black' : 'bg-v2-off-white border-gray-200',
						disabled ? 'opacity-50' : 'hover:bg-v2-brown-darker hover:text-v2-off-white',
					)}
					disabled={disabled || addToCartLoading}
					onClick={() => addToCart(product, variant.id, variant.title, setAddToCartLoading)}>
					{title}
				</Clickable>
			)}
		</>
	);
};

VariantButton.propTypes = {
	addToCart: PropTypes.func,
	isMobile: PropTypes.bool,
	product: PropTypes.object,
	setAddToCartLoading: PropTypes.func,
	variant: PropTypes.object,
};

const CartOffers = () => {
	const [fullProduct, setProduct] = useState();
	const cart = useCartStore();

	const activeOffer = {
		copy: 'Add a Maroon Goldilocks hoodie for 62% off',
		heading: 'Special Offer',
		discountedPrice: 29.99,
		imageSrc:
			'https://cdn.shopify.com/s/files/1/0234/5963/files/hoodie_562c1574-9943-4189-a0e5-839c8b387bef.jpg?v=1732057743&width=1536&quality=75',
		shopifyProductId: '7087208923205',
	};

	useEffect(() => {
		async function fetchProduct() {
			const fullProduct = await getProductById(activeOffer.shopifyProductId);
			setProduct(fullProduct);
		}
		fetchProduct();
	}, []);

	const addToCart = async (product, variantId, selectedTitle, setAddToCartLoading) => {
		const attributes = [];

		const line = {
			attributes,
			variantId,
			quantity: 1,
		};

		const lines = linesFromVariants([line]);

		const analyticsData = {
			price: product.prices[0],
			product: {
				id: createGid('Product', product.id),
				images: product.images,
				productType: product.productType,
				title: product.title,
				prices: product.prices,
				vendor: product.brand || 'Shinesty',
			},
			quantity: 1,
		};

		const variant = fullProduct.variants.find((variant) => {
			return variant.id && variant.id.toString() === variantId;
		});

		if (variant) {
			analyticsData.variantId = variant.id;
			analyticsData.variantSku = variant.sku;
			analyticsData.variantTitle = selectedTitle;
		}

		setAddToCartLoading(true);

		try {
			await cart.addItems({
				analytics: [analyticsData],
				lines: lines,
				success: () => {
					setAddToCartLoading(false);
				},
				error: () => {
					setAddToCartLoading(false);
				},
			});
		} catch (e) {
			setAddToCartLoading(false);
			console.log(e);
		}
	};

	return (
		<div className="border-2 border-v2-brown-darker p-4 m-2 rounded-lg">
			<div className="flex gap-4">
				<div className="flex justify-between flex-col">
					<div className="grid">
						<Typography font="moret" variant="micro" className="underline">
							{activeOffer.heading}
						</Typography>
						<Typography variant="micro">{activeOffer.copy}</Typography>
					</div>

					{fullProduct && (
						<div className="mt-4">
							{fullProduct.variants.length > 1 && (
								<Typography variant="heading-xs" className="mb-2">
									Select Size
								</Typography>
							)}
							<div>
								<Typography>
									<span className="line-through">79.99</span>{' '}
									<span className="font-bold text-primary">29.99</span>
								</Typography>
							</div>
							<div className="flex flex-wrap gap-2">
								{fullProduct.variants.map((variant, i) => (
									<VariantButton
										key={i}
										variant={variant}
										addToCart={addToCart}
										product={fullProduct}
										isMobile={false}
									/>
								))}
							</div>
						</div>
					)}
				</div>

				<Image
					alt="Gift Image"
					className="rounded-lg animate-pulse"
					src={activeOffer.imageSrc}
					height={300}
					width={300}
					objectFit="contain"
				/>
			</div>
		</div>
	);
};

export default CartOffers;
