export const ADD_TO_CART_ID = '__addToCartId';
export const ADD_TO_CART_TIME = '__addToCartTime';
export const ALWAYS_ON_FREE_GIFT = '__alwaysOnFreeGift';
export const ANNUAL = 'Annual';
export const AUTO_REMOVE = '__autoRemove';
export const BUILD_A_PACK = '__buildAPack';
export const CAN_CHANGE_QUANTITY = '__canChangeQuantity';
export const DEFAULT_IMAGE = '__defaultImage';
export const DISCOUNTED_SUBSCRIPTION_PROMOTION = '__dspr';
export const DISCOUNTED_LINE_PRICE = '__discountedLinePrice';
export const DISCOUNTED_SUB_PRICE = '__discountedSubPrice';
export const EXPECTED_CHILDREN_COUNT = '__expectedChildrenCount';
export const FIRST_PAIR_IMAGE = '__firstPairImage';
export const FIRST_PAIR_IMAGES = '__firstPairImages';
export const FIRST_PAIR_TITLE = '__firstPairTitle';
export const FLAVOR = '__flavor';
export const FORTY_OFF_PACK_DISCOUNT = '40offpackdiscount';
export const FREE_ITEM_QUANTITY = '__freeLineItemQuantity';
export const FROM_MIXED_PACK_PRODUCT_ID = '__fromMixedPackProductID';
export const FROM_MIXED_PACK_PRODUCT = '__fromMixedPackProduct';
export const FROM_MIXED_PACK_TITLE = '__fromMixedPackTitle';
export const FROM_PACK_PRODUCT = '__fromPackProduct';
export const FROM_PACK_PRODUCT_ID = '__fromPackProductId';
export const FROM_PACK_PRODUCT_TITLE = '__fromPackProductTitle';
export const FROM_PACK_SKU = '__fromPackSku';
export const FROM_PRODUCT_HANDLE = '__fromProductHandle';
export const FROM_WOMENS_IMAGE = '__fromWomensImg';
export const FROM_WOMENS_LISTING = '__fromWomensListing';
export const FROM_WOMENS_TITLE = '__fromWomensTitle';
export const GIFT_ID = '__giftId';
export const HAS_SUB_ITEMS = '__hasSubItems';
export const HIDE_QUANTITY = '__hideQuantity';
export const IS_CHRISTMAS = '__isChristmas';
export const IS_DISCOUNTED_COOLING = '__isDiscountedCooling';
export const IS_FOURGY = '__isFourgy';
export const IS_FREE_ITEM = '__isFreeItem';
export const IS_HIDDEN = '__isHidden';
export const IS_OPTED_OUT = '__isOptedOut';
export const IS_SUB_TOGGLE = '__isSubToggle';
export const IS_SUB_UP = '__issubup';
export const IS_SUBSCRIPTION = '__isSubscription';
export const IS_SUBSCRIPTION_CHILD = '__isSubscriptionChild';
export const IS_SUBSCRIPTION_PARENT = '__isSubscriptionParent';
export const LINE_PRICE = '__linePrice';
export const LINK_TO = '__linkTo';
export const MENU_UPSELL = '__menuUpsell';
export const MINI_CART_UPSELL = '__minicartUpsell';
export const MIXED_PACK_BUILDER = '__mixedPackBuilder';
export const MIXED_PACK_PRODUCT = '__mixedPackProduct';
export const MINI_CART_DATA = '__miniCartData';
export const MYSTERY_IMAGE = '__mysteryImage';
export const MYSTERY_PACK_ITEM = '__mysteryPackItem';
export const ORIGINAL_PRICE = '__originalPrice';
export const PACK_CHILD = '__packChild';
export const PACK_CONFIG_ID = '__packConfigId';
export const PACK_ID = '__packID';
export const PACK_IS_SPECIAL = '__packIsSpecial';
export const PACK_MASTER = '__packMaster';
export const PACK_PRODUCT = '__packProduct';
export const PACK_VARIANT_GRADUATED = '__packVariantGraduated';
export const PACK_VARIANT_GRADUATED_SKIP_ONE = '__packVariantGraduatedSkipOne';
export const POST_SUB_UPSELL = '__post_sub_upsell';
export const PREBUILT_PACK_TYPE = '__preBuiltPackType';
export const PREPAID_TYPE = 'Prepaid Type';
export const PRICE_TEST = '__priceTest';
export const PRODUCT_TYPE = '__productType';
export const PROMOTION_MONEY_BACK_ESTIMATE = '__promotionMoneyBackEstimate';
export const PROMOTION_TYPE = '__promotionType';
export const PROMOTIONAL_ITEM = '__promotionalItem';
export const PROMOTIONAL_ITEM_INTERACTION = '__promotionalItemInteraction';
export const PROMOTIONAL_PRICE = '__promotionalPrice';
export const PROMOTIONAL_TIER = '__promotionalTier';
export const SHIPPING_TEST_VARIANT = '__shippingTestGroup';
export const SHOW_DISCOUNT = '__showDiscount';
export const SITEWIDE_PACK_BUILDER = '__sitewidePackBuilder';
export const SINGLE_ITEM_PRICE = '__SingleItemPrice';
export const SPECIAL_PROMO = '__specialPromo';
export const SUB_BUY_NOW = '__sub_buy_now';
export const SUB_ITEM_PREFIX = '__subItem-';
export const SUBSCRIPTION_FREQUENCY = '__subscriptionFrequency';
export const SUBSCRIPTION_ID = '__subscriptionId';
export const SUBSCRIPTION_PRICE = '__subscriptionPrice';
export const SUBSCRIPTION_SUBTITLE = '__subscriptionSubTitle';
export const SUBSCRIPTION_TITLE = '__subscriptionTitle';
export const SUBSCRIPTION_TYPE = '__subscriptionType';
export const TIER_PRICE = '__tierPrice';
