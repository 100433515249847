// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
import '/styles/globals.css';
import 'react-medium-image-zoom/dist/styles.css';

import * as blotoutBing from '@blotoutio/providers-bing-sdk';
import * as blotoutFacebook from '@blotoutio/providers-facebook-sdk';
import * as blotoutGa4 from '@blotoutio/providers-google-analytics-4-sdk';
import * as blotoutGoogleAds from '@blotoutio/providers-google-ads-clicks-sdk';
import * as blotoutTiktok from '@blotoutio/providers-tiktok-sdk';

import { ApolloProvider } from '@apollo/client';
import { CartProvider } from '/state/cart-context';
import { CartStore } from '/state/cart-store';
import CookiesAlert from '/components/modals/Cookies';
import { DiscountCodeProvider } from '../state/discount-code-context';
import { DiscountCodeStore } from '../state/discount-code-store';
import ErrorBoundry from '/components/ErrorBoundry';
import { GlobalProvider } from '/state/global-context';
import { GlobalStore } from '/state/global-store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Head from '/components/Head';
import IncludedScripts from '/components/IncludedScripts';
import PropTypes from 'prop-types';
import { SizeProvider } from '/state/size-context';
import { SizeStore } from '/state/size-store';
import { UtmProvider } from '/state/utm-context';
import { UtmStore } from '/state/utm-store';
import { booleanFormat } from '/utils/common-utils';
import client from '/utils/apollo-client';
import { getRemoteNavigationConfig } from '/services/navigation';
import { getRemotePromotionConfig } from '/services/promotions';
import { init as initAlgolia } from '/services/analytics/algolia';
import { init as initShinestyAnalytics } from '/services/analytics/shinesty-analytics';
import { init as initWunderkind } from '/services/analytics/wunderkind';
import { trackPageView } from '/services/analytics/shinesty-analytics';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { consent as consentBlotout, init as initBlotout } from '@blotoutio/edgetag-sdk-js';

const cartStore = new CartStore();
const discountCodeStore = new DiscountCodeStore();
const globalStore = new GlobalStore();
const utmStore = new UtmStore();
const sizeStore = new SizeStore();

const ShinestyStorefront = ({ Component, pageProps, props }) => {
	const getLayout = Component.getLayout || ((page) => page);
	const router = useRouter();

	globalStore.setPromotionalConfig(props.promotionalConfig);
	globalStore.setNavigationConfig(props.navigationConfig);

	useEffect(() => {
		const handleRouteStart = () => {
			globalStore.toggleLoadingBar(true);
		};

		const handleRouteComplete = () => {
			globalStore.toggleLoadingBar(false);
			trackPageView();
		};

		router.events.on('routeChangeStart', handleRouteStart);
		router.events.on('routeChangeComplete', handleRouteComplete);

		globalStore.setCurrentPage(router.asPath);

		return () => {
			router.events.on('routeChangeStart', handleRouteStart);
			router.events.off('routeChangeComplete', handleRouteComplete);
		};
	}, [router]);

	//need to update this function
	function getCartId(query) {
		const rx = new RegExp('[?&]' + 'cartId' + '=([^&]+).*$');
		const returnVal = query.match(rx);
		return returnVal === null ? '' : decodeURIComponent(returnVal[1].replace(/\+/g, ' '));
	}

	useEffect(() => {
		async function initializeApp() {
			let cartId = router.route == '/' ? getCartId(router.asPath) : router.query?.cartId;
			globalStore.setState(props.state);
			await globalStore.setNavigationConfig(props.navigationConfig);
			await cartStore.initQueryCart(cartId, router.query);

			initBlotout({
				edgeURL: 'https://bisto.shinesty.com',
				providers: [blotoutBing, blotoutGa4, blotoutGoogleAds, blotoutFacebook, blotoutTiktok],
			});

			const cookieConsent = globalStore.getCookieConsent();
			consentBlotout({ all: booleanFormat(cookieConsent) });
			initAlgolia();
			initWunderkind();
			initShinestyAnalytics();
			utmStore.initUtmParams(router.query, document.referrer, discountCodeStore);
			await globalStore.setCurrentCountryByCode(props.country, cartStore);
		}
		initializeApp();
	}, []);

	useEffect(() => {
		const initShippingTest = async () => {
			if (props.country === 'US') {
				await cartStore.getShippingTestVariant();
			}
		};
		initShippingTest();
	}, []);

	return (
		<ApolloProvider client={client}>
			<GlobalProvider value={globalStore}>
				<CartProvider value={cartStore}>
					<DiscountCodeProvider value={discountCodeStore}>
						<UtmProvider value={utmStore}>
							<SizeProvider value={sizeStore}>
								<GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
									<ErrorBoundry>
										<IncludedScripts />
										<Head />
										{getLayout(<Component {...pageProps} />)}
										<CookiesAlert country={props.country} state={props.state} />
									</ErrorBoundry>
								</GoogleReCaptchaProvider>
							</SizeProvider>
						</UtmProvider>
					</DiscountCodeProvider>
				</CartProvider>
			</GlobalProvider>
		</ApolloProvider>
	);
};

ShinestyStorefront.getInitialProps = async ({ ctx }) => {
	const promotionalConfig = await getRemotePromotionConfig();
	const navigationConfig = await getRemoteNavigationConfig();

	let countryString;
	let stateString;

	ctx?.req?.rawHeaders?.map((item, i) => {
		if (item.toLowerCase() == 'request_country') {
			countryString = ctx.req.rawHeaders[i + 1];
		}
		if (item.toLowerCase() == 'request_state') {
			stateString = ctx.req.rawHeaders[i + 1];
		}
	});

	// passing country on the query string will override any geo-located country
	if (ctx?.query?.country) {
		countryString = ctx.query?.country;
	}

	const country =
		countryString || ctx?.res?.getHeader('request_country') || ctx?.req?.cookies['request_country'];
	const state =
		stateString || ctx?.res?.getHeader('request_state') || ctx?.req?.cookies['request_state'];

	return {
		props: {
			navigationConfig,
			promotionalConfig,
			country,
			state,
		},
	};
};

ShinestyStorefront.propTypes = {
	Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
	country: PropTypes.string,
	navigationConfig: PropTypes.object,
	pageProps: PropTypes.object.isRequired,
	promotionalConfig: PropTypes.object,
	props: PropTypes.object,
	state: PropTypes.string,
};

export default ShinestyStorefront;
