import ClickToDetails from './ClickToDetails';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
import Price from '../Price';
import ProductCardAction from './ProductCardAction';
import ProductCardBanner from '../discount-codes/ProductCardBanner';
import ProductCardPrintSelect from '/components/products/ProductCardPrintSelect';
import ProductCardWrapper from './ProductCardWrapper';
import PropTypes from 'prop-types';
import Stars from '/components/Stars';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { formatCollectionProduct } from '/services/static/product-format';
import { getProductPageConfig } from '/services/static/pdp-config';
import { getStars } from '/services/junip';
import { pluralize } from '/utils/pluralize';
import { useEffect, useState } from 'react';

const ProductCard = ({
	className = 'bg-v2-off-white',
	product,
	heapLocation,
	onClick,
	rank,
	backgroundTestActive,
}) => {
	const [activeProduct, setActiveProduct] = useState(product);
	const [reviews, setReviews] = useState({});
	const [isTouchDevice, setIsTouchDevice] = useState(false);
	const formattedProduct = formatCollectionProduct(activeProduct);
	const [showQuickAdd, setShowQuickAdd] = useState(false);

	const quickAddValid = formattedProduct?.quickAddValid;

	const coverBackgroundTypes = [
		'blazers',
		'dresses',
		'flight suits',
		'gifts',
		'onesies',
		'ski suits',
		'suit pants',
		"women's blazers",
	];

	const objectPositionTopTypes = ['underwear packs', 'subscription'];

	useEffect(() => {
		const onTouchStart = () => {
			setIsTouchDevice(true);
		};

		document.addEventListener('touchstart', onTouchStart);

		return () => {
			document.removeEventListener('touchstart', onTouchStart);
		};
	}, []);

	const handleMouseOver = () => {
		if (!isTouchDevice) {
			setShowQuickAdd(true);
		}
	};

	const handleMouseLeave = () => {
		if (!isTouchDevice) {
			setShowQuickAdd(false);
		}
	};

	useEffect(() => {
		setActiveProduct(product);
	}, [product]);

	useEffect(() => {
		async function fetchReviews() {
			const res = await getStars(product?.id);
			setReviews(res);
		}
		if (product.id) {
			fetchReviews();
		}
	}, [product]);

	if (!formattedProduct) {
		return null;
	}

	function updateProduct(newProduct) {
		setActiveProduct(newProduct);
	}

	if (!formattedProduct?.handle) {
		return (
			<div className="h-[600px] w-[48vw] md:w-[24vw] flex items-center align-middle justify-center">
				<Loading height="16px" width="16px" />
			</div>
		);
	}
	const showBackground =
		backgroundTestActive &&
		product?.product_type?.includes('Boxer') &&
		!product?.product_type?.includes('Cooling');
	const pdpConfig = getProductPageConfig(product);

	const imagePosition = coverBackgroundTypes.includes(product.shopifyProductType)
		? 'cover'
		: 'contain';

	const savings = Math.round(
		((formattedProduct.priceFull - formattedProduct.price) / formattedProduct.priceFull) * 100,
	);

	return (
		<ProductCardWrapper
			onMouseOver={() => handleMouseOver()}
			onMouseLeave={() => handleMouseLeave()}
			showQuickAdd={showQuickAdd && quickAddValid}>
			<ClickToDetails
				className="h-full"
				product={formattedProduct}
				onClick={onClick}
				rank={rank}
				heapLocation={heapLocation}>
				{formattedProduct.imageSrc && (
					<div className="group relative h-full">
						<div className="aspect-h-1 aspect-w-1 flex h-full w-full overflow-hidden rounded-t-md md:rounded-t-lg">
							<Image
								src={formattedProduct.imageSrc}
								alt={formattedProduct.imageAlt}
								height={pdpConfig.imageHeight || 500}
								width={500}
								objectFit={imagePosition}
								className={classNames(
									'h-full w-full group-hover:opacity-75',
									showBackground ? 'bg-gradient-to-b from-gray-100 to-gray-200' : '',
									objectPositionTopTypes.includes(product.shopifyProductType)
										? 'object-top'
										: 'object-center',
								)}
							/>
						</div>
						{formattedProduct.sticker && (
							<div className="absolute right-0 top-0 z-10 h-[64px] w-[64px] md:h-[110px] md:w-[130px]">
								<Image
									alt="product sticker"
									layout="fill"
									objectFit="contain"
									src={formattedProduct.sticker}
								/>
							</div>
						)}
					</div>
				)}
			</ClickToDetails>

			<div className="relative mt-auto">
				<div className="mx-3 pt-2">
					<ProductCardPrintSelect
						product={activeProduct}
						updateProduct={updateProduct}
						heapLocation={heapLocation}
					/>
				</div>

				<ClickToDetails product={formattedProduct}>
					<div className="mx-3 text-left pt-2">
						<Typography className="font-semibold mt-1" component="h2" variant="body">
							{formattedProduct.name}
						</Typography>

						<div className="flex items-center pt-1">
							<Stars rating={reviews.ratingAverage} variant="small" />
							<Typography
								className="text-right mt-0.5 ml-1 md:ml-1 whitespace-nowrap text-v2-brown"
								variant="small">
								{reviews.ratingCount > 1000
									? `${(reviews.ratingCount / 1000).toFixed()}k`
									: reviews.ratingCount}{' '}
								{pluralize('Review', reviews.ratingCount)}
							</Typography>
						</div>

						{formattedProduct.description && (
							<Typography
								component="h2"
								className="mt-1 text-v2-brown-darker h-6 truncate"
								variant="micro">
								{formattedProduct.description}
							</Typography>
						)}

						<div className="mt-1 text-v2-brown-darker md:flex">
							{formattedProduct.showFrom ? 'From ' : ''}
							{formattedProduct.priceFull ? (
								<span aria-hidden="true" className="line-through text-gray-500 italic mr-2">
									<Price
										format={'explicit'}
										internationalPricing={formattedProduct?.international_pricing?.compare_at_price}
										price={formattedProduct.priceFull}
									/>
								</span>
							) : null}
							<span
								className={classNames(
									formattedProduct.priceFull ? 'text-primary font-semibold' : '',
								)}>
								<Price
									format={'explicit'}
									product={product}
									internationalPricing={formattedProduct?.international_pricing?.price}
									price={formattedProduct.price}
								/>
							</span>
							<ProductCardBanner product={product} />
							{product.shopifyProductType.includes('pack') && savings > 0 && (
								<Typography className="ml-2">Pack Sale: Save {savings}%</Typography>
							)}
						</div>
					</div>
				</ClickToDetails>
				{quickAddValid && (
					<>
						<button
							className="md:hidden flex items-stretch align-middle justify-around z-20"
							onClick={() => setShowQuickAdd(!showQuickAdd)}>
							<div>
								<Typography variant="heading-xs" className="ml-3 py-1">
									Add To Cart
								</Typography>
							</div>
							<Typography className="ml-1 mt-[6px]" variant="heading-xs">
								{showQuickAdd ? (
									<Icon name="minus" height="12px" width="12px" />
								) : (
									<Icon name="plus" height="12px" width="12px" />
								)}
							</Typography>
						</button>
						<ProductCardAction
							className={className}
							product={formattedProduct}
							setShowQuickAdd={setShowQuickAdd}
							showQuickAdd={showQuickAdd}
						/>
					</>
				)}
			</div>
		</ProductCardWrapper>
	);
};

ProductCard.propTypes = {
	backgroundTestActive: PropTypes.bool,
	className: PropTypes.string,
	heapLocation: PropTypes.string,
	onClick: PropTypes.func,
	product: PropTypes.object,
	rank: PropTypes.number,
};

export default ProductCard;
