import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const hideBanner = true;
const FreeShippingBanner = ({ freeShippingThreshold, processedCart, subscriptionInCart }) => {
	if (hideBanner) {
		return null;
	}
	const progress =
		processedCart.subTotal <= freeShippingThreshold
			? processedCart.subTotal
			: freeShippingThreshold;

	return (
		<div className="bg-gray-600 text-center py-2 text-white">
			{processedCart.subTotal < freeShippingThreshold && !subscriptionInCart ? (
				<>
					<div className="flex px-2 text-left pb-2">
						<Typography variant="micro">
							You&apos;re ${Math.round(freeShippingThreshold - progress)} away from{' '}
							<span className="font-semibold">50% off overnight shipping:</span>
						</Typography>
						<Typography variant="body" className="ml-auto">
							${freeShippingThreshold}
						</Typography>
					</div>
					<div className="h-4 bg-gray-300 mx-2 rounded-full text-right">
						<div
							className="h-full bg-green-600  rounded-full"
							style={{ width: `${(progress / freeShippingThreshold) * 100}%` }}></div>
					</div>
				</>
			) : (
				<>
					{subscriptionInCart ? (
						<div>
							<Typography variant="subhead-md">free shipping with your subscription</Typography>
						</div>
					) : (
						<div>
							<Typography variant="subhead-md" className="not-italic">
								You&apos;ve unlocked 50% Off Overnight Shipping
							</Typography>
						</div>
					)}
				</>
			)}
		</div>
	);
};

FreeShippingBanner.propTypes = {
	freeShippingThreshold: PropTypes.number,
	processedCart: PropTypes.object,
	subscriptionInCart: PropTypes.bool,
};

export default FreeShippingBanner;
