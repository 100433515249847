import { getFirstProperty } from '/utils/array';

const checkInventory = (shopifyProduct) => {
	if (
		shopifyProduct.productType === 'Subscription' ||
		shopifyProduct.productType === 'Underwear Packs'
	) {
		return true;
	}
	for (let i = 0; i < shopifyProduct?.variants?.length; i++) {
		if (parseInt(shopifyProduct?.variants[i]?.dcInventory?.value) > 0) {
			return true;
		}
	}
	return false;
};

const transformShopifyProduct = (shopifyProduct) => {
	if (!shopifyProduct) {
		return {};
	}

	const formatted = {
		handle: shopifyProduct.handle,
		image: getFirstProperty(shopifyProduct, 'images', 'src'),
		inStock: checkInventory(shopifyProduct),
		price: getFirstProperty(shopifyProduct, 'prices'),
		productType: shopifyProduct.productType,
		tags: shopifyProduct.tags,
		title: shopifyProduct.title,
		titlePrimary: shopifyProduct.title,
		variants: shopifyProduct.variants,
	};

	if (shopifyProduct.id) {
		formatted.shopifyProductId = shopifyProduct.id;
	}

	if (shopifyProduct.title.includes('|')) {
		const split = shopifyProduct.title?.split(' | ');
		if (split?.length > 1) {
			formatted.titlePrimary = split[0];
			formatted.titleSecondary = split[1];
		}
	}

	return formatted;
};

export default transformShopifyProduct;
